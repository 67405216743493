<template>
<div class="page-reporte-excel-banco">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="SEPSA" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                    <v-col sm="12" md="2" lg="2">
                        <br/>
                        <h5>Fecha: {{this.model.fecha}}</h5>
                    </v-col>
                      <v-col sm="12" md="3" lg="3">
                        <v-radio-group v-model="model.bolsas" row :rules="[rules.required]">
                          <template v-slot:label>
                            <div>Num. Bolsas:</div>
                          </template>
                          <v-radio label="1" :value="1" @click="model.nombre_bolsa2=''; model.nombre_bolsa3='';"></v-radio>
                          <v-radio label="2" :value="2" @click="model.nombre_bolsa3='';"></v-radio>
                          <v-radio label="3" :value="3"></v-radio>
                        </v-radio-group>                        
                      </v-col>
                      <v-col sm="12" md="3" lg="3">
                        <v-row>
                            <v-text-field label="Bolsa 1" v-model="model.nombre_bolsa1" :rules="[rules.required]"></v-text-field>
                        </v-row>
                        <v-row v-if="model.bolsas >=2">
                            <v-text-field label="Bolsa 2" v-model="model.nombre_bolsa2" :rules="[rules.required]"></v-text-field>
                        </v-row>
                        <v-row v-if="model.bolsas ==3">
                            <v-text-field label="Bolsa 3" v-model="model.nombre_bolsa3" :rules="[rules.required]"></v-text-field>
                        </v-row>
                      </v-col>
                </v-row>                
                <v-row>
                    <v-col sm="12" md="2" lg="2">
                    </v-col>
                    <v-col sm="12" md="3" lg="3">
                        <v-text-field prepend-icon="$" @change="setTotal()" @keyup="setTotal()" label="Efectivo" v-model="model.total_efectivo" :rules="[validaCantidadFloat]"></v-text-field>
                    </v-col>                
                    <v-col sm="12" md="3" lg="3">
                        <v-text-field v-show="model.tiene_cheques==1" prepend-icon="$" @change="setTotal()" @keyup="setTotal()" label="Cheques" v-model="model.total_cheques" :rules="[validaCantidadFloat]"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                        <br/>
                        <h4 style="color:red;">TOTAL: ${{formatNumber(total.toFixed(2))}}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="2" lg="2">
                    </v-col>
                    <v-col sm="12" md="3" lg="3">
                        <v-checkbox label="Cheques" v-model="model.tiene_cheques" true-value="1" false-value="0" primary hide-details
                            @change="setTotal()"
                        ></v-checkbox>
                        <v-text-field v-if="model.tiene_cheques==1" label="Cantidad de Cheques" v-model="model.cantidad_cheques" 
                            :rules="[ValidaMaximo, validaCantidadEntera]"
                            @input="setCheques()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" lg="3">
                        <table v-if="model.tiene_cheques==1">
                            <tr>
                                <th colspan="2">Cheques</th>                                
                            </tr>
                            <tr v-for="(cheque, index) in model.cheques"  v-bind:key="index">
                                <td><v-text-field :name="'numero['+index+']'" label="Número" type="text"
                                    class="center-input" autocomplete="off" :rules="[rules.required]"
                                    v-model="cheque.numero"></v-text-field>
                                </td>
                                <td>&nbsp;</td>
                                <td><v-text-field :name="'valor['+index+']'" label="Valor" type="text"
                                    class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat]"
                                    v-model="cheque.valor"></v-text-field>
                                </td>
                            </tr>
                        </table>                        
                    </v-col>
                    <v-col sm="12" md="4" lg="4">
                        <span><strong>{{model.total_letra}}</strong></span>
                    </v-col>
                </v-row>
                
                
                
                
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click.native="generar_reporte('excel')">
              <v-icon>mdi-file-excel</v-icon> Generar Excel
            </v-btn>            
            <v-btn color="blue" dark @click.native="generar_reporte('word')">
              <v-icon>mdi-file-word</v-icon> Generar Word
            </v-btn>            
          </v-card-actions>        

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
import Vue from "vue";
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },



  },
  mounted: function() {
    if (!this.verificaPermiso('t.cortes.cortes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {    
    var today = window.moment().format("DD-MM-YYYY");        
    this.model.fecha = today;    
  },
  data() {
    return {
       total:0,       
      registros:[],
      columns: [
        "sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante","monto_total_final"
      ],
      options: {
        filterable: ["sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante"],
        sortable: ["sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante"],
        pagination: false,         
        headings: {
          "supervisor.nombre":"Supervisor",
          "sucursal.nombre":"Sucursal",
          "efectivo_retirado":"Efectivo Retirado",
          "monto_voucher":"Total en Vouchers",
          "monto_transferencia":"Transferencias",
          "monto_cheques":"Cheques",
          "monto_total_final": "Monto Total",            
        },
      },
      sucursales:[],
      loading_sucursal:false,
      menu1: false,
      menu2: false,
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "SEPSA",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {
        fecha:"",
        bolsas:1,
        nombre_bolsa1:"",
        nombre_bolsa2:"",
        nombre_bolsa3:"",
        total_efectivo:0,
        total_cheques:0,
        total_letra:"",
        tiene_cheques:0,
        cantidad_cheques:"",
        cheques:[]
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    setTotal:function(){
        try {
            this.total = 0;
            if(this.model.total_efectivo && parseFloat(this.model.total_efectivo)>0){
                this.total += parseFloat(this.model.total_efectivo);
            }
            if(this.model.tiene_cheques.toString()=="1" && this.model.total_cheques && parseFloat(this.model.total_cheques)>0){
                this.total += parseFloat(this.model.total_cheques);
            } else {
                this.model.total_cheques = 0;
            }
            this.model.total_letra = window.funciones.numeroALetras(this.total, 'PESOS') + " M.N.";
        } catch (error) {
            console.log(error);
        }
    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;
    },
    ValidaMaximo: function(value){
        if(value && parseInt(value) > 7){
            return "Número máximo 7";
        }
        return true;
    },
    addNewCheque: function() {
      this.model.cheques.push(Vue.util.extend({}, []));      
    },
    setCheques: function(){
        try {
            if(parseInt(this.model.cantidad_cheques) > 0){                
                let len_cheques = this.model.cheques.length;
                var cant_cheques = parseInt(this.model.cantidad_cheques);
                if(cant_cheques > len_cheques){
                    for(var i=0; i<(cant_cheques-len_cheques); i++ ){
                        this.addNewCheque();
                    }
                }else if (cant_cheques < len_cheques){
                    this.model.cheques = [];
                    for(var i=0; i<cant_cheques; i++ ){
                        this.addNewCheque();
                    }
                }
            } else {
                this.model.cheques = [];
            }
                
        } catch (error) {
            
        }
        
        
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if (time != null && time.toString() != "") {
        if (time.toString().length != 5)
          return "Hora no valida1";
        if (!time.match(/^\d{2}:(?:[0-5]\d)$/))
          return "Hora no valida2";
        var time_arr = time.split(":");
        if (time_arr.length != 2) {
          return "Hora no valida3";
        } else {
          if (isNaN(time_arr[0]) || isNaN(time_arr[1])) {
            return "Hora no valida4";
          }
          if (parseInt(time_arr[0]) < 24 && parseInt(time_arr[1]) < 60) {
            return true;
          } else {
            return "Hora no valida5";
          }
        }
      }
      return true;

    },    
    
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    
    generar_reporte: function(opcion='excel') {
      if (this.$refs.form.validate()) {
        try {
          
            if(this.model.total_efectivo == undefined || this.model.total_efectivo == null || this.model.total_efectivo == ""){
                this.model.total_efectivo = 0;
            }
            if(this.model.total_cheques == undefined || this.model.total_cheques == null || this.model.total_cheques == ""){
                this.model.total_cheques = 0;
            }
            
            window.dialogLoader.show('Espere un momento por favor..');

            if(opcion == "excel"){
                var url = process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_sepsa/";                
                var nombre = "Ficha.xlsx";
            } else {
                var url = process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_sepsa_word/";                
                var nombre = "Ficha SEPSA.docx";

            }
            window.axios
            .post(url, this.model, {            
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                        }),
                        url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = nombre;
                    a.click();
                    a.parentNode.removeChild(a);
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();
                });
        } catch (error) {
          console.log("ERRORS: ", error);
        }
      }
    },



  }
}
</script>
